import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { array, bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import {
  CardMedia,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ageData, snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { updateProfilForInfluencer } from "../../services/influencer/InfluencerDetails";
import MuiPhoneNumber from "material-ui-phone-number";
import { PROFILE_IMG } from "../../config/main.json";
import { makeStyles } from "@mui/styles";
import LocationInput from "../general/LocationInput";
import { editOnDetailsChange } from "../../services/campaign/CampaignDetails";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      height: 48,
    },
  })
);

const genderData = ["Male", "Female", "Other", "Decline to self identify"];

const ViewUpdateDialog = ({
  influencerAccount,
  viewUpdateDialog,
  closeViewUpdateDialog,
  platform,
  influencerAccounts,
  setInfluencerAccountList,
}) => {
  const [scroll, setScroll] = useState("paper");
  const [name, setName] = useState(influencerAccount.name);
  const [profileUrl, setProfileUrl] = useState(influencerAccount.profile_url);
  const [totalFollowers, setTotalFollowers] = useState(influencerAccount.total_followers);

  const [original, setOriginal] = useState({
    name: influencerAccount.name,
    profileUrl: influencerAccount.profile_url,
    age: influencerAccount.age_range,
    location: influencerAccount.location,
    gender: influencerAccount.gender,
    totalFollowers: influencerAccount.total_followers,
  });
  const [location, setLocation] = useState(influencerAccount?.location);
  const [selLocation, setSelLocation] = useState("");
  const [gender, setGender] = useState(influencerAccount?.gender);
  const [age, setAge] = useState(influencerAccount?.age_range);
  const [disable, setDisable] = useState(true);
  const [isCorrectLocation, setIsCorrectLocation] = useState(true);
  const descriptionElementRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    if (e.target.value !== selLocation) {
      setIsCorrectLocation(false);
    }
  };

  const handlePlaceSelected = (place) => {
    setIsCorrectLocation(true);
    const locationData = {
      city: "",
      state: "",
      country: "",
    };
    place.address_components.forEach((item) => {
      switch (item.types[0]) {
        case "locality":
          locationData.city = item.long_name;
          break;
        case "administrative_area_level_1":
          locationData.state = item.long_name;
          break;
        case "country":
          locationData.country = item.long_name;
          break;
        default:
      }
    });
    const locationStr = `${locationData.city}, ${locationData.country}, ${locationData.state}`;
    setLocation(locationStr);
    setSelLocation(locationStr);
  };

  useEffect(() => {
    if (viewUpdateDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setProfileUrl(influencerAccount?.profile_url || "");
      setName(influencerAccount?.name || "");
      setLocation(influencerAccount?.location || "");
      setGender(influencerAccount?.gender || "");
      setAge(influencerAccount?.age_range);
      setTotalFollowers(influencerAccount?.total_followers);
    }
  }, [viewUpdateDialog]);

  const handleClose = (event) => {
    closeViewUpdateDialog(event);
  };

  const updateProfile = () => {
    if (!isCorrectLocation) {
      setIsCorrectLocation(false);
      return;
    }
    setIsCorrectLocation(true);
    let totalFollowersAsString = totalFollowers ? totalFollowers.toString() : "0";
    // Update name, username, total followers and add email, phone numbers if any
    updateProfilForInfluencer(
      influencerAccount.id,
      name,
      totalFollowersAsString,
      profileUrl,
      influencerAccount.type,
      platform?.toLowerCase(),
      age,
      gender,
      location
    ).then((result) => {
      if (result) {
        dispatch(
          openSnackbar({
            message:
              result.failed === false ? "Updated influencer's profile successfully" : "Unable to update influencer's profile",
            severity: result.failed === false ? "success" : "error",
          })
        );


        updateInfluencerList("name");
        updateInfluencerList("profile_url");
        const updatedPaylod = {
          id: influencerAccount.id,
          age,
          gender,
          location,
        };

        if (original.age === age) delete updatedPaylod.age;
        if (original.gender === gender) delete updatedPaylod.gender;
        if (original.location === location) delete updatedPaylod.location;
        if (Object.keys(updatedPaylod).length > 0) editOnDetailsChange({ id: influencerAccount.id, ...updatedPaylod });
        handleClose(true);
      }
    });
  };

  const updateInfluencerList = (attribute) => {
    if (influencerAccounts && influencerAccounts.length > 0) {
      const accountList = [];
      influencerAccounts.forEach((account) => {
        if (account.id === influencerAccount.id) {
          if (attribute === "name") {
            account.name = name;
          } else if (attribute === "profile_url") {
            account.profileUrl = profileUrl;
          } else if (attribute === "email") {
            account.email = "";
          } else if (attribute === "phone") {
            account.phone = "";
          }
        }
        accountList.push(account);
      });
      setInfluencerAccountList(accountList);
      handleClose(true);
    }
  };

  // Function to decide if update should be enabled or not by mathing existing values to original values.
  const checkWithOriginal = () => {
    let disable = true;
    if (name === "") {
      setDisable(disable);
      return;
    }
    if (
      name !== original.name ||
      totalFollowers !== original.total_followers ||
      location !== original.location ||
      profileUrl !== original.profileUrl ||
      gender !== original.gender ||
      age !== original.age
    ) {
      disable = false;
    }
    // if (totalFollowers !== original.totalFollowers) {
    //   disable = false;
    // }
    // if (profileUrl !== original.profileUrl) {
    //   disable = false;
    // }

    setDisable(disable);
  };

  useEffect(() => {
    checkWithOriginal();
  }, [original, name, profileUrl, location, gender, age, totalFollowers]);

  return (
    <Dialog
      open={viewUpdateDialog}
      onClose={() => handleClose(false)}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableEnforceFocus
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={influencerAccount.photoUrl ? influencerAccount.photoUrl : PROFILE_IMG}
              alt={influencerAccount.name}
            />
          </Grid>
          <Grid item xs={7}>
            <span className={styles.cardBody}>{influencerAccount.name}</span>
            <br />
            <span className={styles.cardHeading}>Edit Profile</span>
          </Grid>
          {Array.isArray(platform) ? (
            platform.map((item, index) => (
              <Grid item key={index}>
                <Typography sx={textColor[item]}>
                  {snapshotHeaderIcons[item]}
                </Typography>
              </Grid>
            ))
          ) : (
            platform && (
              <Grid item xs={1}>
                <Typography sx={textColor[platform]}>
                  {snapshotHeaderIcons[platform]}
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Name of the influencer"
                variant="outlined"
                defaultValue={influencerAccount.name}
                style={{ width: "100%" }}
                onChange={(event) => setName(event.target.value)}
                required={true}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Profile Url of the influencer"
                variant="outlined"
                defaultValue={influencerAccount.profile_url}
                style={{ width: "100%" }}
                onChange={(event) => setProfileUrl(event.target.value)}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Total followers"
                variant="outlined"
                defaultValue={influencerAccount.total_followers}
                style={{ width: "100%" }}
                onChange={(event) => setTotalFollowers(event.target.value)}
                required={true}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="age-label">Age Range (in yrs)</InputLabel>
                <Select
                  labelId="age-label"
                  id="age-select"
                  defaultValue={influencerAccount.age_range}
                  value={age}
                  label="Age Range (in yrs)"
                  onChange={(e) => setAge(e.target.value)}
                >
                  {ageData.map((item) => (
                    <MenuItem value={item.value}>{item.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender-select"
                  defaultValue={influencerAccount?.gender}
                  value={gender}
                  label="Gender"
                  onChange={(e) => setGender(e.target.value)}
                >
                  {genderData.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocationInput
                label="Location"
                name="location"
                placeholder="Start typing to autofill"
                variant="outlined"
                onChange={handleLocationChange}
                value={location || ""}
                sx={{ width: "100%" }}
                placeSelectedHandler={handlePlaceSelected}
              />
              <br />
              {!isCorrectLocation && <p style={{ color: "red" }}>Please select valid location</p>}

              {influencerAccount?.location && <FormLabel>Current Location: {influencerAccount?.location}</FormLabel>}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose(false)} className={classes.input}>
          Cancel
        </Button>
        <Button variant="contained" onClick={updateProfile} className={classes.input} disabled={disable}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ViewUpdateDialog.propTypes = {
  influencerAccount: object.isRequired,
  viewUpdateDialog: bool.isRequired,
  closeViewUpdateDialog: func.isRequired,
  platform: string.isRequired,
  influencerAccounts: array.isRequired,
  setInfluencerAccountList: func,
};

export default ViewUpdateDialog;
